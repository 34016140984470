import appFirebase from '../credenciales'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { getFirestore, collection } from 'firebase/firestore'

const db = getFirestore(appFirebase)

export default function ElectricoList({ path }) {
  const query = collection(db, path);
  const [docs] = useCollectionData(query);

  return (
    <ul>
      {docs?.map((doc) => (
        <li key={Math.random()}>
        {doc.id ? 'Sin datos'  : doc.electrico_id}
        </li>
      )
      )}
    </ul>
  );
}