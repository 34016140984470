import React, { useEffect, useState } from 'react'
import { PDFDownloadLink } from "@react-pdf/renderer";
import DocuPDF from "./DocuPDF";
import appFirebase from '../credenciales'
import { getAuth, signOut } from 'firebase/auth'
import { getFirestore, addDoc, getDocs, doc, getDoc, setDoc, collection } from 'firebase/firestore'
import { useCollectionData } from "react-firebase-hooks/firestore";
import TrabajoList from "./TrabajoList";
import ElectricoList from "./ElectricoList";

const auth = getAuth(appFirebase)
const db = getFirestore(appFirebase)

const Home = ({ correoUsuario }) => {

  const valorInicial = {
    uid: '',
  }

  const query = collection(db, "pauta_trabajo");
  const [docs, loading, error] = useCollectionData(query);

  //console.log(docs, loading, error)

  //variables de estado
  const [user, setUser] = useState(valorInicial)
  const [lista, setLista] = useState([])
  const [subId, setSubId] = useState('')

  //console.log(lista)


  //funcion captura data
  const capturarInputs = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value })
  }

  const guardarDatos = async (e) => {
    e.preventDefault();

    if (subId === '') {

      try {
        await addDoc(collection(db, 'pauta_trabajo'), {
          ...user
        })
      } catch (error) {
        console.log(error);
      }

    }
    else {
      await setDoc(doc(db, 'pauta_trabajo', subId), {
        ...user
      })
    }


    setUser({ ...valorInicial })
    setSubId('')
    window.location.reload();

  }

  //funcion para renderizar lista de usuarios



  useEffect(() => {

    // console.log(useEffect)

    const getLista = async () => {

      try {

        const querySnapshot = await getDocs(collection(db, 'pauta_trabajo'))

        const docs = []

        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id })
        })
        setLista(docs)

      } catch (error) {
        console.log(error)
      }
    }
    getLista()
  }, [])



  const getOne = async (id) => {
    try {
      const docRef = doc(db, "pauta_trabajo", id)
      const docSnap = await getDoc(docRef)
      setUser(docSnap.data())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (subId !== '') {
      getOne(subId)
    }
  }, [subId]
  )




  return (



    <div className="container">
      <p>Bienvenido, <strong>{correoUsuario}</strong> haz iniciado sesion</p>

      <button className="btn btn-primary" onClick={() => signOut(auth)}>
        Cerrar session
      </button>
      <hr />



      <div className='row'>
        {/* esta seccion es del formulario */}

        {/* esta session sera la lista de nuestros usuarios */}
        <div className='col-md-12'>
          <h2 className='text-center'>Registros</h2>


          <div className="card">
            <div className="card-header">
            </div>
            <div className="card-body">
              <p className="card-text">

                <form onSubmit={guardarDatos}>
                  <div className='card card-body'>
                    <div className='form-group'>

                      <input type="text" name="uid" hidden className='form-control mb-3' placeholder='Ingresar uid ' onChange={capturarInputs} value={user.uid = subId} />

                    </div>

                    <button className="btn btn-primary">
                      {subId === '' ? 'Guardar' : 'Generar reporte'}
                    </button>
                  </div>
                </form>

              </p>
              <div className="alert alert-info">
                <div className="alert-body">
                  <h4 className="text-warning">Listado pautas</h4>
                  <p>

                  </p>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap">Mina</th>
                    <th scope="col" className="text-nowrap">Supervisor</th>
                    <th scope="col" className="text-nowrap">Fecha</th>
                    <th scope="col" className="text-nowrap">Estado</th>
                    <th scope="col" className="text-nowrap">Trabajos</th>
                    <th scope="col" className="text-nowrap">Electricos</th>
                    <th scope="col" className="text-nowrap">Generar</th>
                    <th scope="col" className="text-nowrap">Descargar</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    lista.map(list => (

                      

                      <tr className="table-active" key={list.id}>
                        <td>{list.mina_id ? list.mina_id : "..."}</td>
                        <td>{list.supervisor_id ? list.supervisor_id : "..."}</td>
                        <td>{list.fecha.toDate().toLocaleString() ? list.fecha.toDate().toLocaleString() : "..."}</td>
                        <td>
                          {list.estado ? 'Cerrado' : 'En proceso'}
                        </td>
                        <td>
                          {loading && "Loading..."}
                          {list.uid ? <TrabajoList path={`pauta_trabajo/${list.uid}/detalle_pauta_trabajo`} />
                            : 'Se debe generar el reporte'}
                        </td>
                        <td>
                        {loading && "Loading..."}
                        {list.uid ? <ElectricoList path={`pauta_trabajo/${list.uid}/electricos_pauta_trabajo`} />
                          : 'Se debe generar el reporte'}
                      </td>
                        <td>
                          <button onClick={() => setSubId(list.id)}>
                            Generar
                          </button>
                        </td>
                        <td>
                        {list.uid ? <PDFDownloadLink
                          document={<DocuPDF pauta={list}/>}
                          fileName="pauta_trabajo.pdf">
                          <button >Descargar PDF</button>
                        </PDFDownloadLink>
                          : 'Se debe generar el reporte'}
                          
                        </td>
                        
                      </tr>
                    ))

                  }

                </tbody>
              </table>





            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default Home