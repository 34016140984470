// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDEmpNXzGl6BR0PVG_Tt2Qr8G60Qjh9M9k",
  authDomain: "departamento-electrico.firebaseapp.com",
  databaseURL: "https://departamento-electrico-default-rtdb.firebaseio.com",
  projectId: "departamento-electrico",
  storageBucket: "departamento-electrico.appspot.com",
  messagingSenderId: "1029957317301",
  appId: "1:1029957317301:web:3e2a46b62dbf0932fe84fc"
};

// Initialize Firebase
const appFirebase = initializeApp(firebaseConfig);

export default appFirebase;
