import React from "react";
import { Document, Page, Text, View,StyleSheet,Font,Image } from "@react-pdf/renderer";
import appFirebase from '../credenciales';
import { getFirestore, collection,doc } from 'firebase/firestore';
import { useCollectionData } from "react-firebase-hooks/firestore";
import miImagen from '../images/logo.png';
import siCheck from '../images/check.png';
import noCheck from '../images/no_check.png';
import "firebase/storage";



const db = getFirestore(appFirebase)

const DocuPDF = ({ pauta }) => {

  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });
  
   const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 20,
      textAlign: 'center',
      fontFamily: 'Times-Roman'
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,    
      marginVertical: 1,
      fontFamily: 'Oswald'
    },
      subtitleData: {
      fontSize: 10,
      margin: 12,    
      marginVertical: 1,
      fontFamily: 'Oswald'
    },
    text: {
      margin: 12,
      fontSize: 10,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      //marginVertical: 1,
      //marginHorizontal: 230,
      justifyContent: 'center',
      alignItems: 'center',
      width: 90,
      height: 20
  
    },

    estado: {
      justifyContent: 'center',
      alignItems: 'center',
      height: 10
  
    },

    firma: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 110,
      height: 40
    },

    header: {
      fontSize: 13,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
      borderTopWidth: 120,
      
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  
    tableRow: {
      flexDirection: 'row',
      fontSize: 10,
      borderBottomWidth: 1,
      borderColor: '#0000',
      alignItems: 'center',
      borderTopWidth: 1,
      borderLeftWidth: 1,
    },
    tableCol: {
      padding: 5,
      borderRightWidth: 1,
      borderColor: '#000',
      fontSize: 10,
      flexGrow: 1,
      width: 350,
    },
      tableCold: {
      padding: 2,
      borderLeftWidth: 0,
      borderRightWidth: 1,
      borderColor: '#000',
      fontSize: 10,
      flexGrow: 1,
      width: 0,
  
    },
      tableColds: {
      padding: 2,
      borderLeftWidth: 0,
      borderRightWidth: 1,
      borderColor: '#000',
      fontSize: 10,
      flexGrow: 5.1,
    },
    tableColdss: {
      padding: 2,
      borderLeftWidth: 0,
      borderRightWidth: 1,
      borderColor: '#000',
      fontSize: 10,
      flexGrow: 1,
    },
    tableHeader: {
      backgroundColor: '#f0f0f0',
      fontSize: 10,
      fontWeight: 'bold',
      width: 1,
    },
    
      tableMargen: {
        margin: 12,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      left: 50,
      right: 50,
      textAlign: 'center',
      fontSize: 10,
      color: 'grey',
    },
    page: {
      padding: 30,
    },

    footerBarra: {
      position: 'absolute',
      fontSize: 10,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    
    
    
     footerFirma: {
      position: 'absolute',
      fontSize: 10,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  
  });

  const path=(`pauta_trabajo/${pauta.uid}/detalle_pauta_trabajo`);

  const query = collection(db, path);
  const [docs] = useCollectionData(query);  
 // console.log(filterNumbers); // output [4 , 5]

 const pathe=(`pauta_trabajo/${pauta.uid}/electricos_pauta_trabajo`);

 const querye = collection(db, pathe);
 const [docse] = useCollectionData(querye); 


  return (
    <Document>

    <Page size="letter" style={styles.page}>
    <Image
    style={styles.image}
    src={miImagen}/>


  
    <Text style={styles.title}>PAUTA DIARIA DE TRABAJOS</Text>
    <Text style={styles.author}>SERVICIOS GENERALES MINA</Text>

   
    <Text style={styles.subtitleData}>
    Mina:                          {pauta.mina_id ? pauta.mina_id : "..."}
    </Text>
  
    <Text style={styles.subtitleData}>
    Supervisor:                 {pauta.supervisor_id ? pauta.supervisor_id : "..."}
    </Text>   

    <Text style={styles.subtitleData}>
    FECHA:                       {pauta.fecha.toDate().toLocaleString()}
    </Text>   


    <View style={styles.tableMargen}>


    <View style={styles.tableRow}>
      <Text style={[styles.tableColds, styles.tableHeader]}>NOMBRE DE PARTICIPANTES</Text>
    </View>
  
    { docse?.map((doce) => (
    <View style={styles.tableRow}>
    <ul style={styles.tableColdss}>
    <li key={Math.random()}>
    
    <Text>  
     {doce.id ? 'Sin datos'  : doce.electrico_id}
    </Text>  

 </li>

</ul>
      </View>
      )
      )}

    </View>
    <View style={styles.tableMargen}>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCold, styles.tableHeader]}>DESCRIPCION  DEL  TRABAJO</Text>
      <Text style={[styles.tableCold, styles.tableHeader]}>LUGAR DE TRABAJO DESIGNADO</Text>
      <Text style={[styles.tableCold, styles.tableHeader]}>ESTADO</Text>

    </View>
  

      {docs?.map((doc) => (
      <View key={Math.random()} style={styles.tableRow}>
            <Text style={styles.tableCold}>  
            {doc.id ? 'Sin datos'  : doc.descripcion }
            </Text>   

            <Text style={styles.tableCold}>  
            {doc.id ? 'Sin datos'  : doc.lugar }
            </Text>  

            <Text style={styles.tableCold}>  
            {doc.estado_detalle ? <Image style={styles.estado} src={siCheck}/> : 
            <Image style={styles.estado}
            src={noCheck}/>}

            </Text>  
      </View>
      )
      )}





  




    </View>



        


    <Text style={styles.footerBarra}>
    __________________________
  </Text>
      


  <Text style={styles.footerFirma}>
     
  {pauta.firma_electrico ? <Image
    style={styles.firma}
    src={pauta.firma_electrico ? pauta.firma_electrico : "..."}/> : 
        'No disponible'}
</Text>


      </Page>
    </Document>
  );
};

export default DocuPDF;